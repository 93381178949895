export const dataTypes = {
    AIRLINES: 'AIRLINES',
    ARTICLES: 'ARTICLES',
    AUTH: 'AUTH',
    AIRPORTS: 'AIRPORTS',
    AMADEUSLOG: 'AMADEUSLOG',
    BLACKLIST: 'BLACKLIST',
    CITIES: 'CITIES',
    CLIENTS: 'CLIENTS',
    EDITCLIENTS: 'EDITCLIENTS',
    CLIENTTYPES: 'CLIENTTYPES',
    CURRENCIES: 'CURRENCIES',
    DESTINIES: 'DESTINIES',
    EMAILLOG: 'EMAILLOG',
    EMAILTEMPLATE: 'EMAILTEMPLATE',
    FILEUPLOADS: 'FILEUPLOADS',
    FLIGHTCLASSES: 'FLIGHTCLASSES',
    FOOTERCATEGORIES: 'FOOTERCATEGORIES',
    FOOTERMENU: 'FOOTERMENU',
    HISTORYRESERVATIONS: 'HISTORYRESERVATIONS',
    HELPCATEGORIES: 'HELPCATEGORIES',
    MENU: 'MENU',
    CARDS: 'CARDS',
    DASHBOARD: 'DASHBOARD',
    COUNTRIES: 'COUNTRIES',
    FLIGHTS: 'FLIGHTS',
    FLIGHTSCHEMAS: 'FLIGHTSCHEMAS',
    LANGUAGES: 'LANGUAGES',
    LISTARTICLESNOTINCLUDE: 'LISTARTICLESNOTINCLUDE',
    LOG: 'LOG',
    MUNICIPALITIES: 'MUNICIPALITIES',
    PAYWAYS: 'PAYWAYS',
    PAYMENTSOUT: 'PAYMENTOUT',
    PRINCIPALMENU: 'PRINCIPALMENU',
    PRINCMENUCATEGORIES: 'PRINCMENUCATEGORIES',
    PROVINCES: 'PROVINCES',
    PUBLICITIES: 'PUBLICITIES',
    REFRESH: 'REFRESH',
    REGIONS: 'REGIONS',
    RESERVATIONS: 'RESERVATIONS',
    PLACES: 'PLACES',
    PLANES: 'PLANES',
    PROBLEMS: 'PROBLEMS',
    ROLES: 'ROLES',
    FIREWALLS: 'FIREWALLS',
    TRANSLATES: 'TRANSLATES',
    USERS: 'USERS',
    SLIDESHOWS: 'SLIDESHOWS',
    SOCIALMEDIAS: 'SOCIALMEDIAS',
    SYSTEM: 'SYSTEM',
    URLS: 'URLS',
    WIDGETS: 'WIDGETS',
    DELETEBANNERPUBLICITY: 'DELETEBANNERPUBLICITY',
    DELETEIMAGEARTICLE: 'DELETEIMAGEARTICLE',
    DELETEBANNERHELPCATEGORY: 'DELETEBANNERHELPCATEGORY',
    DELETEICONHELPCATEGORY: 'DELETEICONHELPCATEGORY',
    DELETEBANNERMENUCATEGORY: 'DELETEBANNERMENUCATEGORY',
    RESERVATIONSSTATE: 'RESERVATIONSSTATE',
    REQUESTCANCELLATIONS: 'REQUESTCANCELLATIONS',
    CANCELLATIONSTATES: 'CANCELLATIONSTATES',
    REFUNDS: 'REDUNDS',
    REFUNDSTATES: 'REFUNDSTATES',
    CANCELLATIONAMOUNTS: 'CANCELLATIONAMOUNTS'
};

export const iconNameTypes = {
    HOME: 'home',
    SETTINGS: 'settings',
    SECURITY: 'security',
    APPLICATION: 'application',
    CANCELLATION: 'cancellation'
};

export const navTypes = {
    AIRLINES: 'Aerolíneas',
    AIRPORTS: 'Aeropuertos',
    ARTICLES :'Artículos',
    AMADEUSLOG: 'Logs de Reserva de Amadeus',
    BLACKLIST: 'Lista Negra',
    COUNTRY: 'Países',
    CARD: 'Tarjetas',
    CITIES: 'Ciudades',
    CLIENTS: 'Clientes',
    CLIENTTYPES: 'Tipos de clientes',
    CURRENCY: 'Monedas',
    DESTINIES: 'Destinos',
    DISTANCE: 'Distancias',
    HOME: 'Inicio',
    FOOTERCATEGORIES: 'Categorías de menú de pie de página',
    FOOTERMENU: 'Menú de pie de página',
    FLIGHTS: 'Vuelos',
    FLIGHTCLASSES: 'Clases de vuelo',
    FLIGHTSCHEMAS: 'Esquemas de vuelo',
    HISTORYRESERVATIONS: 'Histórico de Reservas',
    HELPCATEGORIES: 'Páginas de ayuda',
    LANG: 'Idiomas',
    LOCATION: 'Lugares',
    LOG: 'Logs',
    EMAILLOG: 'Logs de Correos',
    EMAILTEMPLATE: 'Plantillas de  correo',
    MUNICIPE: 'Municipios',
    PAYWAYS: 'Formas de pago',
    PLACES: 'Lugares',
    PLANES: 'Aviones',
    PRESTPAYMENT: 'Pagos prestatarios',
    PAYMENTSOUT: 'Pagos Transfermóvil',
    PRINCIPALMENU: 'Menú principal',
    PRINCMENUCATEGORIES: 'Categorías del menú principal',
    PROBLEM: 'Problemas',
    PROVINCE: 'Provincias',
    PUBLICITIES: 'Publicidad',
    REGION: 'Regiones',
    RESERVATIONS: 'Reservaciones',
    ROLE: 'Roles',
    SOCIALMEDIAS: 'Redes sociales',
    SLIDESHOWS: 'Imágenes para slide show',
    SECURITY: 'Seguridad',
    SYSTEM: 'Sistema',
    TREATMENT: 'Tratamientos',
    USER: 'Usuarios',
    TRANSLATES: 'Traducciones',
    WEB: 'Web'
};

export const menuTypes = {
    HOME: 'dashboard',
    AIRLINES: 'airlines',
    AIRPORTS: 'airports',
    ARTICLES: 'article',
    AMADEUSLOG: 'amadeuslog',
    BLACKLIST: 'suspiciousips',
    COUNTRY: 'country',
    CARD: 'creditcard',
    CLIENTS: 'client',
    CLIENTTYPES: 'clienttype',
    CITY: 'city',
    CURRENCY: 'currency',
    DESTINIES: 'destinity',
    EMAILLOG: 'emaillog',
    EMAILTEMPLATE: 'emailtemplate',
    FLIGHTCLASSES: 'flightclass',
    FOOTERMENU: 'footermenu',
    FOOTERCATEGORIES: 'footermenucategory',
    FLIGHTS: 'flights',
    FLIGHTSCHEMAS: 'flightschemas',
    HISTORYRESERVATIONS: 'historyreservations',
    HELPCATEGORIES: 'helpcategory',
    LOCATION: 'location',
    PROVINCE: 'province',
    MUNICIPE: 'municipality',
    LANG: 'lang',
    LOG: 'log',
    DISTANCE: 'unitdistance',
    TREATMENT: 'treatment',
    WEB: 'referenceweb',
    PAYWAY: 'payway',
    PAYMENTOUT: 'paymentout',
    PLACES: 'place',
    PLANES: 'planes',
    PRINCIPALMENU: 'mainmenu',
    PRINCMENUCATEGORIES: 'mainmenucategory',
    PROBLEMS: 'problemservicearea',
    PRESTPAYMENT: 'prestatariopago',
    PUBLICITIES: 'publicity',
    REGIONS: 'regions',
    RESERVATIONS: 'reservations',
    ROLE: 'role',
    USER: 'user',
    SOCIALMEDIAS: 'socialnetwork',
    SECURITY: 'security',
    SLIDESHOW: 'slideshow',
    SYSTEM: 'config',
    TRANSLATES: 'message',
    CANCELLATIONREQUEST: 'cancellationrequest'
}

export const actionsTypes = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    INDEX: 'index',
    SHOW: 'show',
    SETCLIENTTYPE: 'setclienttype'
}

export const httpErrorsTypes = (errorType) => {
    switch (errorType) {
        case 401:
        case 400:
            return 'Datos no válidos';
        case 500:
            return 'Ha ocurrido un error en el servidor';
        default:
            return 'Datos no válidos'
    }
};

export const modalTypes = {
    NONE: 'NONE',
    SIMPLE: 'SIMPLE',
    DUAL: 'DUAL'
}

export const navUrls = {
    [menuTypes.AIRLINES]: `/panel/${menuTypes.AIRLINES}`,
    [menuTypes.AIRPORTS]: `/panel/${menuTypes.AIRPORTS}`,
    [menuTypes.ARTICLES]: `/panel/${menuTypes.ARTICLES}`,
    [menuTypes.AMADEUSLOG]: `/panel/${menuTypes.AMADEUSLOG}`,
    [menuTypes.BLACKLIST]: `/panel/${menuTypes.BLACKLIST}`,
    [menuTypes.DESTINIES]: `/panel/${menuTypes.DESTINIES}`,
    [menuTypes.HOME]: '/panel',
    [menuTypes.CARD]: `/panel/${menuTypes.CARD}`,
    [menuTypes.CITY]: `/panel/${menuTypes.CITY}`,
    [menuTypes.CLIENTS]: `/panel/${menuTypes.CLIENTS}`,
    [menuTypes.CLIENTTYPES]: `/panel/${menuTypes.CLIENTTYPES}`,
    [menuTypes.COUNTRY]: `/panel/${menuTypes.COUNTRY}`,
    [menuTypes.EMAILLOG]: `/panel/${menuTypes.EMAILLOG}`,
    [menuTypes.EMAILTEMPLATE]: `/panel/${menuTypes.EMAILTEMPLATE}`,
    [menuTypes.FOOTERCATEGORIES]: `/panel/${menuTypes.FOOTERCATEGORIES}`,
    [menuTypes.FOOTERMENU]: `/panel/${menuTypes.FOOTERMENU}`,
    [menuTypes.FLIGHTS]: `/panel/${menuTypes.FLIGHTS}`,
    [menuTypes.FLIGHTCLASSES]: `/panel/${menuTypes.FLIGHTCLASSES}`,
    [menuTypes.FLIGHTSCHEMAS]: `/panel/${menuTypes.FLIGHTSCHEMAS}`,
    [menuTypes.HISTORYRESERVATIONS]: `/panel/${menuTypes.HISTORYRESERVATIONS}`,
    [menuTypes.HELPCATEGORIES]: `/panel/${menuTypes.HELPCATEGORIES}`,
    [menuTypes.PAYWAY]: `/panel/${menuTypes.PAYWAY}`,
    [menuTypes.PLACES]: `/panel/${menuTypes.PLACES}`,
    [menuTypes.PLANES]: `/panel/${menuTypes.PLANES}`,
    [menuTypes.PRINCIPALMENU]: `/panel/${menuTypes.PRINCIPALMENU}`,
    [menuTypes.PRINCMENUCATEGORIES]: `/panel/${menuTypes.PRINCMENUCATEGORIES}`,
    [menuTypes.PROBLEMS]: `/panel/${menuTypes.PROBLEMS}`,
    [menuTypes.PROVINCE]: `/panel/${menuTypes.PROVINCE}`,
    [menuTypes.PUBLICITIES]: `/panel/${menuTypes.PUBLICITIES}`,
    [menuTypes.SYSTEM]: `/panel/${menuTypes.SYSTEM}`,
    [menuTypes.MUNICIPE]: `/panel/${menuTypes.MUNICIPE}`,
    [menuTypes.CURRENCY]: `/panel/${menuTypes.CURRENCY}`,
    [menuTypes.LANG]: `/panel/${menuTypes.LANG}`,
    [menuTypes.LOG]: `/panel/${menuTypes.LOG}`,
    [menuTypes.REGIONS]: `/panel/${menuTypes.REGIONS}`,
    [menuTypes.RESERVATIONS]: `/panel/${menuTypes.RESERVATIONS}`,
    [menuTypes.ROLE]: `/panel/${menuTypes.ROLE}`,
    [menuTypes.SOCIALMEDIAS]: `/panel/${menuTypes.SOCIALMEDIAS}`,
    [menuTypes.SLIDESHOW]: `/panel/${menuTypes.SLIDESHOW}`,
    [menuTypes.USER]: `/panel/${menuTypes.USER}`,
    [menuTypes.TRANSLATES]: `/panel/${menuTypes.TRANSLATES}`,
    [menuTypes.PAYMENTOUT]: `/panel/${menuTypes.PAYMENTOUT}`,
    [menuTypes.CANCELLATIONREQUEST]: `/panel/${menuTypes.CANCELLATIONREQUEST}`
}

export const sexTypes = {
    FEMENINE: 'f',
    MASCULINE: 'm'
};

export const widgetTypes = {
    SUMMARY: 'summary',
    LINE_CHART: 'lineChart',
    PIE_CHART: 'pieChart',
    BAR_CHART: 'barChart',
    TABLE: 'table'
};

export const widgetNumberTypes = {
    MONEY: 'd',
    NUMBER: 'n'
};

export const paletteTypes = {
    DARK: 'dark',
    LIGHT: 'light'
};

export const orderStateTypes = {
    COMPLETED: 'Completada',
    CANCEL: 'Cancelada',
    INPROCESS: 'En proceso'
};

export const positionTypes = {
    HOME1: 'home_position_1',
    HOME2: 'home_position_2',
    HOME3: 'home_position_3',
    HOME4: 'home_position_4',
    HOME5: 'home_position_5',
    HOME6: 'home_position_6',
    HOME7: 'home_position_7'
}

export const categMenuTypes = {
    NOTPARENT: 'not_parent',
    ONLYPARENT: 'only_parent',
    ALL: 'all'
}

export const statesType = {
    STATENON: '-1',
    STATE1: '1',
    STATE2: '2',
    STATE3: '3',
    STATE4: '4',
    STATE5: '5',
    STATE6: '6',
    STATE7: '7',
    STATE8: '8',
}

export const stateAmadeusType = {
    [statesType.STATENON]: 'Cancelado',
    [statesType.STATE1]: 'Iniciado',
    [statesType.STATE2]: 'Inic.Amadeus',
    [statesType.STATE3]: 'Inic.Banco',
    [statesType.STATE4]: 'Resp.#1 Banco',
    [statesType.STATE5]: 'Resp.#2 Banco',
    [statesType.STATE6]: 'Conf.Amadeus',
    [statesType.STATE7]: 'Completado OK',
    [statesType.STATE8]: 'Canc.Amadeus'

}

